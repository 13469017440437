/*=NAVIGATION MAIN */
.navi-main {

	@include breakpoint(medium) {
		display: flex;
		justify-content: space-between;
	} // bp

	@include breakpoint(giant) {
		max-width: rem(870px); 
	} // bp

	li {
		border-bottom: rem(1px) solid $light;

		@include breakpoint(medium) {
			border-bottom: none;
		} // bp

		&:last-child {
			border-bottom: none;
		}// &:last-child

		&.home,
		&.firmenprofil,
		&.aktuelles,
		&.facebook,
		&.google,
		&.kontakt,
		&.anfahrt,
		&.datenschutzerklaerung,
		&.impressum {

			@include breakpoint(medium) {
				display: none; 
			} // bp

		}

		&:hover {
			a {

			}
		}

		&.active {

			a:before {
				opacity: 1;
				visibility: visible;
			}
		}

		a {
			display: block;
			text-decoration: none;
			position: relative;
			color: $light;
			padding: rem(9px) 0 rem(9px) rem(9px);
			transition: background-color $default-easing, color $default-easing;
			font-size: rem(15px);

			@include breakpoint(medium) {
				transition: none;
				padding: 0; 
			} // bp

			@include breakpoint(large) {
				font-size: rem(18px); 
			} // bp

			&:before {

				@include breakpoint(medium) {
					content: "";
					position: absolute;
					left: 0;
					bottom: rem(2px);
					z-index: 10;
					width: 100%;
					background-color: $light;
					height: rem(1px);
					opacity: 0;
					visibility: hidden;
					transition: opacity $default-easing, visibility $default-easing;
				} // bp

			}// &:before

			&:hover, &:focus, &.active {
				text-decoration: none;
				background-color: $light;
				color: $primary;

				@include breakpoint(medium) {
					 background-color: transparent;
					 color: $light;
				} // bp

				&:before {

					@include breakpoint(medium) {
						opacity: 1;
						visibility: visible;
					} // bp

				}// &:before

			}// &:hover, &:focus

		}// a

	}// li

}// .navi-main

// Off-canvas navigation
.page-navi {
	background: $primary;
	min-height: 100%;
	transform: translateX(-100%);
	left: 0;
	position: absolute;
	top: 0;
	width: 80%;
	z-index: 100;
	transition: transform $default-easing;

	@media screen and (max-width: 799px) {

		@include breakpoint(tiny) {
			width:rem(320px);
		}// bp

	}// bp

	@include breakpoint(medium) {
		transition: none;
		background: $primary;
		transform: translateX(0);
		transition: none;
		min-height: inherit;
		position: fixed;
		width: 100%;
		backface-visibility: hidden;
		top: 0;
		left: 0;
		padding: rem(18px) 0;
	}

	.office-hours {
		display: none;

		@include breakpoint(giant) {
			display: block; 
			position: absolute;
			right: rem($base-gap);
			top: 0;
			z-index: 20;
			color: #9da1a5;
			font-size: rem(16px);
		} // bp

		strong {
			font-weight: 400;
			display: inline-block;
			margin-right: rem(18px);
		}// strong

	}// .office-hours

}// .page-navi

.toggle-navi, .close-navi {
	color: $light;
	font-size: rem(32px);
	text-decoration: none;

	&:before {
		@extend .fa-default;
		@extend .fa-bars;
		transition: content 300ms;
		line-height: 1em;
	}

	

	@include breakpoint(medium) {
		display: none;
	}
}

.close-navi {
	display: none;
}

.navi-buttons {
	position: fixed;
	top: rem(19px);
	left: rem(5px);
	background: $primary;
	z-index: 66;
	border: rem(3px) solid $light;
	padding: rem(5px) rem(10px);
	transition: background $default-easing, border-color $default-easing;

	@include breakpoint(tiny) {
		left: rem(14px);
	} // bp

	.iphone &, .ipad & {
		padding: rem(4px) rem(10px) rem(2px);
	}// .iphone &

	a:hover {
		color: $light;

		.toggle-navi, .close-navi {
			color: $light;
		}

	}

	@include breakpoint(medium) {
		display: none; 
	} // bp

}// .navi-buttons

#navi-toggled:target {
	.page-navi {
		transform: none;
	}

	.page-wrap {
		transform: translateX(80vw);
		position: fixed;

		@include breakpoint(tiny) {
			transform: translateX(320px);
		}// bp

	}//.page-wrap

	.close-navi {
		display: block;

		&:before {
			@extend .fa-close;
		}
	}

	.toggle-navi {
		display: none;
	}

	@include breakpoint(medium) {
		.close-navi {
			display: none;
		}

		.page-wrap {
			transform: none;
			position: relative;
		}
	}
}

.footer-bottom {
	
	.navi {
		display: none;
	}// .navi

	.navi-add {

		@include breakpoint(medium) {
			display: flex; 
			max-width: rem(645px);
			justify-content: space-between;
		} // bp

		a {
			display: block;
			color: $dark;
			font-size: rem(16px);
			transition: color $default-easing;
			position: relative;
			text-decoration: none;

			&:after {
				content: attr(data-hover);
				position: absolute;
				left: 0;
				top: rem(5px);
				font-weight: 400;
				z-index: 10;
				line-height: 1;
				opacity: 0;
				visibility: hidden;
				background-color: $light;
				color: #5e6f80;
				border-bottom:  rem(1px) solid #5e6f80;
				transition: opacity $default-easing, visibility $default-easing;
			}// &:after


			&:hover, &:focus, &.active {
				color: #5e6f80;

				&:before, &:after {
					opacity: 1;
					visibility: visible;
				}// &:before, &:after

			}// &:hover, &:focus, &.active

		}// a
		
	}// .navi-add

	.navi-social {

		@include breakpoint(medium) {
			display: flex;
		} // bp

		position: absolute;
		top: rem(34px);
		right: rem(14px);;
		justify-content: space-between;


		a {
			text-indent: rem(-9999px);
			overflow: hidden;
			transition: transform 300ms linear;
			display: block;
			background: {
				repeat: no-repeat;
				position: center;
				size: cover;
			}

			&:hover, &:focus {
				transform: scale(.8);
			}// &:hover, &:focus

			&.google {
				background-image: image-url("svg/google-plus.svg");
				width: rem(42px);
				height: rem(42px);
				position: relative;
				top: rem(-6px);
			}// &.google

			&.facebook {
				background-image: image-url("svg/facebook.svg");
				width: rem(29px);
				height: rem(29px);
				margin-right: rem(12px);
			}// &.facebook

		}// a

	}// .navi-social

}// .footer-bottom


