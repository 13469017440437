// Styled list
dl.floatList {
	@include pie-clearfix;

	dt, dd {
		display: block;
		vertical-align: top;
		float: left;
	}

	dt {
		padding-right: .5rem;
		width: 100%;
		clear:left;

		@include breakpoint(tiny) {
			width: 40%;
		} // bp

	}

	dd {
		width: 100%;
		border-bottom: rem(1px) solid rgba($dark, .2);
		padding-bottom: rem(10px);
		margin-bottom: rem(25px);

		@include breakpoint(tiny) {
			padding-bottom: 0;
			border-bottom: none;
			width: 60%;
			margin-bottom: rem(5px);
		} // bp

	}
}

// SOME HELPFUL CLASSES
//////////////////////////////

.highlight {
	color: $secondary;
}

.btn {
	background: $primary;
	border: none;
	color: $light;
	cursor: pointer;
	display: table;
	font-size: 1rem;
	line-height: 1rem;
	padding: .7rem 1rem;
	text-align: center;
	text-decoration: none;
	transition: background-color $default-easing;

	&:hover, &:active, &:focus {
		background: $secondary;
	}

	*[class^="icon"] {
		margin-left: .5rem;
		vertical-align: middle;
	}
}

.notification {
	padding: 1em;
	background: $alert;
	color: $light;
	font-size: 1.2em;
}

// PAGE WRAP
//////////////////////////////

.page-wrap {
	transform: none;
	overflow: hidden;
	transition: transform $default-easing;

	@include breakpoint(medium) {
		transition: none;
	}// bp

}// .page-wrap

// HEADER
//////////////////////////////

.header {
	background: $light;
	position: relative;
	margin-bottom: rem(30px);
	width: 100%;

	@include breakpoint(medium) {
		margin-bottom: rem(61px);
		padding-top: rem(64px); 
	} // bp

	.header-top {
		display: flex;
		flex-direction: column;
		align-items: center;

		@include breakpoint(large) {
			align-items: stretch;
			padding-right: rem(46px);
			flex-direction: row;
			justify-content: space-between;
		} // bp

		.branding {
			display: block;
			float: right;
			width: rem(160px);
			padding-top: rem(25px);
			margin-bottom: rem(40px);

			@include breakpoint(tiny) {
				margin-bottom: rem(25px);
				width: rem(300px);
			} // bp

			@include breakpoint(small) {
				width: rem(350px);
			} // bp

			@include breakpoint(medium) {
				float: none;
				padding-top: rem(25px);
				margin-bottom: rem(25px);
			}

			@include breakpoint(large) {
				margin-bottom: rem(36px);
				padding-top: rem(34px);
				width: auto; 
			} // bp

		}// .branding

		.contact-info {
			display: flex;
			color: #535353;
			font-size: rem(16px);
			padding-bottom: rem(22px);

			@include breakpoint(large) {
				padding-top: rem(26px);
				flex-basis: rem(453px);
				justify-content: space-between;
			} // bp

			strong {
				font-weight: 500;
			}

			a {
				text-decoration: none;

				&:hover, &:focus {
					text-decoration: underline;
				}// &:hover, &:focus

			}

			ul {

				@include breakpoint(large) {
					padding-top: rem(8px);
				} // bp

				&:first-child {
					display: none;

					@include breakpoint(small) {
						display: block; 
					} // bp

				} //&:first-child

				&:last-child {

					@include breakpoint(small) {
						border-left: rem(1px) solid #dddddd;
						padding-left: rem(26px);
						margin-left: rem(26px);
					} // bp

					@include breakpoint(large) {
						margin-left: 0;
					} // bp
					
					li {

						&:nth-child(2) {
							@include breakpoint(giant) {
								display: none; 
							} // bp
						}//&:nth-child

					}// li

				}// &:last-child

				li {

					&:first-child {
						line-height: 1.375em;
					}// &:first-child

					&:last-child {
						line-height: 1.500em;
					}// &:last-child

				}// li

			}// ul

		}// .contact-info

	}// .header-top

	.header-bottom {
		min-height: rem(300px);
		display: flex;
	    flex-direction: column;
	    justify-content: flex-end;
	    background: {
	    	image: image-url("bg-header-smallest.jpg");
	    	repeat: no-repeat;
	    	position: center;
	    	size: cover;
	    }
	    margin: {
	    	left: rem(-$base-gap);
	    	right: rem(-$base-gap);
	    }

	    @include breakpoint(small) {
			min-height: rem(350px);
	    	background-image: image-url("bg-header-small.jpg");
	    } // bp

		@include breakpoint(medium) {
			margin: 0;
			background: {
				image: none;
				repeat: no-repeat;
				position: center;
				size: cover;
			}
	    	justify-content: flex-start;
			flex-direction: row;
			display: block;
			min-height: 0;
		} // bp
		
		.slideshow {
			display: none;

			@include breakpoint(medium) {
				display: block; 
				margin-bottom: rem(1px);
			} // bp
			
			.slide {
				min-height: rem(369px);
				background: {
					repeat: no-repeat;
					position: center;
					size: cover;
				}

				&.slide-01 {
					background-image: image-url("slideshow/bg-slide-01.jpg");
				}// &.slide-01

				&.slide-02 {
					background-image: image-url("slideshow/bg-slide-02.jpg");

					.nojs & {
						display: none;
					}// .nojs &

				}// &.slide-01

			}// .slide

		}// .slideshow

		.btn-box {
			display: none;

			@include breakpoint(medium) {
				display: flex;
				position: relative;
				z-index: 10; 
			} // bp

			> * {
				flex-basis: 50%;

				.iexplore & {
					display: block;
				}// .iexplore &

			}

			a {
				text-decoration: none;
				text-align: center;
				font-size: rem(18px);
				text-transform: uppercase;
				background-color: $secondary;
				transition: background-color $default-easing;
				padding: rem(22px) 0;
				color: $light;
				position: relative;

				&:before {

					@include breakpoint(medium) {
						content: "";
						position: absolute;
						left: 50%;
						top: rem(-17px);
						z-index: -1;
						width: rem(53px);
						height: rem(53px);
						background-color: $primary;
						border-left: rem(1px) solid $light;
						border-top: rem(1px) solid $light;
						opacity: 0;
						visibility: hidden;
						transform: translateX(-50%) rotate(45deg);
						transition: opacity $default-easing, visibility $default-easing;
					} // bp


					body:not(.index) & {
						display: none;
					}// body:not(.index) &

				}// &:before

				&:last-child {
					border-left: rem(1px) solid $light;
				}// &:last-child

				&:hover, &:focus {
					background-color: $primary;

					&:before {
						opacity: 1;
						visibility: visible;
					}// &:before

				}// &:hover, &:focus

			}

		}// .btn-box

	}// .header-bottom
	
}// .header

// MAIN CONTENT
//////////////////////////////

main {
	display: block;
	margin-bottom: rem(40px);

	@include breakpoint(medium) {
		margin-bottom: rem(73px);
	} // bp

	p {
		margin-bottom: rem(22px);
	}//p

	ul {
		@extend .styled-list;
	}

	.google-maps {
		@extend .video-container;

		@include breakpoint(medium) {
			height: rem(400px);
		}

		.nojs & {
			display: none;
		}// .nojs &

	}

	.separator, .teaser-box {
		max-width: rem(2000px);
		margin: 0 auto rem(65px);
		position: relative;
	}// .separator, .teaser-box

	.separator {

		> .row > .col {
			position: relative;

			&:before {
				content: "";
				position: absolute;
				left: rem($base-gap);
				right: rem($base-gap);
				z-index: 10;
				height: rem(1px);
				background-color: rgba($dark, .3);
				bottom: rem(-2px);
			}// &:before

		}// > .row > .col


	}// .separator

	.teaser-box {
		background-color: #f4f6f8;
		color: $primary;
		padding-top: rem(30px);
		padding-bottom: rem(30px);

		@include breakpoint(large) {
			padding-top: rem(86px);
			padding-bottom: rem(91px);
		} // bp

		.teaser {
			margin-bottom: rem(30px);

			p {
				margin-bottom: rem(28px);
			}// p

			&:not(.teaser-aktuelles) {
				padding-top: rem(30px);
				border-top: rem(1px) solid $primary;

				@include breakpoint(small) {
					border-top: none;
					padding-top: 0;
				} // bp

			}

			@include breakpoint(medium) {
				margin-bottom: 0;
			} // bp
			
			&.teaser-aktuelles {

				@include breakpoint(small) {
					margin-bottom: rem(40px);
				} // bp


				@include breakpoint(medium) {
					margin-bottom: 0; 
				} // bp

			}// &.aktuelles

		}// .teaser

		&:before, &:after {
			content: "";
			position: absolute;
			left: 0;
			z-index: 10;
			width: 100%;
			height: rem(1px);
			background-color: #eff0f0;
		}// &:before, &:after

		&:before {
			top: rem(-2px);
		}// &:before

		&:after {
			bottom: rem(-2px);
		}// &:after


		h2 {
			color: #0d458a;
			font-size: rem(22px);
			font-weight: 300;
			margin-bottom: rem(10px);

			@include breakpoint(medium) {
				text-transform: uppercase;
				margin-bottom: rem(30px);
				font-size: rem(24px);
			} // bp

		}// h2

	}// .teaser-box
}

// FOOTER
//////////////////////////////

.footer {
	display: block;
	border-bottom: rem(30px) solid $primary;

	@include breakpoint(medium) {
		border-bottom: none;
	} // bp

	.footer-top {
		margin-bottom: rem(30px);

		@include breakpoint(medium) {
			margin-bottom: rem(94px);
		} // bp
		
		.call-box {
			background-color: $primary;
			color: $light;
			display: flex;
			justify-content: space-between;
			padding: rem(20px);
			flex-direction: column;
			margin-bottom: rem(30px);

			@include breakpoint(small) {
				flex-direction: row;
			} // bp

			@include breakpoint(medium) {
				margin-bottom: 0;
				min-height: rem(267px);
				flex-direction: column;
				padding: {
					top: rem(55px);
					left: rem(20px);
					right: rem(20px);
					bottom: rem(55px);
				}
			} // bp

			@include breakpoint(large) {
				flex-direction: row;
			} // bp

			@include breakpoint(giant) {
				padding: {
					top: rem(55px);
					left: rem(63px);
					right: rem(79px);
					bottom: rem(55px);
				} 
			} // bp

			@include breakpoint(medium) {
				background: {
					color: transparent;
				 	image: image-url("bg-davert.jpg");
				 	repeat: no-repeat;
				 	position: center;
				 	size: cover;
				 } 
			} // bp

			.call-us {
				font-size: rem(18px);
				font-weight: 300;
				line-height: 1.667em;
				margin-bottom: rem(15px);

				@include breakpoint(small) {
					margin-bottom: 0;
				} // bp

				@include breakpoint(large) {
					padding-top: rem(43px);
				} // bp

				span {
					display: block;
					font-size: rem(20px);
					line-height: 1.28;
					text-transform: uppercase;

					@include breakpoint(large) {
						font-size: rem(30px);
					} // bp

				}// span

			}// .call-us

			> div {

				@include breakpoint(large) {
					 border-left: rem(1px) solid rgba($light, .3);
					 padding: {
					 	left: rem(20px);
					 	top: rem(50px);
					 }
				} // bp

				@include breakpoint(giant) {
					padding-left: rem(43px); 
				} // bp

				ul {
					font-size: rem(16px);

					.phone-info {
						font-size: rem(20px);

						@include breakpoint(large) {
							font-size: rem(30px);
						} // bp

					}// .phone-info

				}// ul

				strong {
					font-weight: 400;
					display: block;
					margin-right: rem(18px);

					@include breakpoint(tiny) {
						display: inline-block;
					} // bp

				}// strong

			}// > div

		}// .call-box

		.align-vertical {
			display: flex;;
			align-items: center;

			.iexplore & {
				display: block;
				padding-top: rem(58px);
			}// .iexplore &

			> p {
				margin-bottom: 0;

				@include breakpoint(huge) {
					padding-left: rem(17px);
				} // bp

			}// > p

		}// .align-vertical

	}// .footer-top

	.footer-bottom {

		@include breakpoint(medium) {
			padding: {
				top: rem(35px);
				bottom: rem(34px);
			}
			border-top: rem(1px) solid rgba($dark, .3);
		} // bp

		
	}// .footer-bottom

}
